import React, { Component } from 'react';
import { Text, View,  TextInput, StyleSheet, TouchableWithoutFeedback, ScrollView, Pressable, Dimensions, TouchableOpacity, TouchableHighlight } from 'react-native';
import { SearchBar } from 'react-native-elements';
import DrugsList from './druglist';
import * as Animatable from 'react-native-animatable';

class DrugSearch extends Component {
  state = {
    searchQuery: '',
    limit: 20,
    total: 0,
    cursor: '',
    catType: null,
    selectedIndex: null,
  }

  handleSubmit = event => event.preventDefault();

  handleInputChange = (val) => {
    this.setState(
      { searchQuery: val }
      )
  }

  handleButtonClick = (id) => {
    this.setState({
       catType: id,
       selectedIndex: id,
    });
  }

  queryMe = data => {
    this.setState({
      total: data,
    })
  };

  render() {
    const { state, handleSubmit, handleInputChange, handleButtonClick, queryMe } = this;
    const { searchQuery, limit, total, cursor, catType, selectedIndex } = state;
    const filterList = [
      {
        id: null,
        title: 'View All',
        slug: 'all',
        color: '#042945'
      },
      {
        id: 37,
        title: 'Permitted',
        subtitle: 'No Warnings',
        slug: 'permitted',
        color: '#0B8E36'
      },
      {
        id: 38,
        title: 'Prohibited',
        subtitle: 'At All Times',
        slug: 'prohibited-at-all-times',
        color: '#CE0E2D'
      },
      {
        id: 39,
        title: 'Prohibited',
        subtitle: 'In-Competition',
        slug: 'prohibited-in-competition',
        color: '#F39200'
      },
      {
        id: 40,
        title: 'Prohibited',
        subtitle: 'In Certain Sports',
        slug: 'prohibited-in-certain-sports',
        color: '#662483'
      },
      {
        id: 41,
        title: 'Prohibited',
        subtitle: 'Male Athletes',
        slug: 'prohibited-male-athletes',
        color: '#008ECC'
      },
    ];
    
    const listFilter = filterList.map((button) => (
        <TouchableHighlight key={button.slug} id={button.id} onPress={(e)=>this.handleButtonClick(button.id)}>
          <View style={[styles.FilterItems, this.state.selectedIndex == null ? {backgroundColor: button.color} : button.id == this.state.selectedIndex ? { backgroundColor: button.color} : styles.FilterItemsDeselected]}>
            <Text style={styles.FilterHeader}>{button.title}</Text> 
            {!!button.subtitle && (<Text style={styles.FilterSubHeader}>{button.subtitle}</Text>)}
          </View>
        </TouchableHighlight>
      )
    );
    return (
      <View style={styles.MainContainer}>
          <SearchBar
            value={searchQuery}
            onChangeText={(val)=>handleInputChange(val)}
            placeholder="Type medication name here…"
            containerStyle={styles.SearchContainer}
            inputContainerStyle={styles.SearchInputContainer}
            inputStyle={styles.SearchInput}
            placeholderTextColor={'#FFF'}
            searchIcon={{ color:"white"}}
            clearIcon={{color:"white"}}
          />
          <View style={styles.FilterContainer}>
            {listFilter}
          </View>
        <DrugsList searchQuery={searchQuery} limit={limit} queryMe={queryMe} cursor={cursor}  catType={catType}/>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  MainContainer: {
    flex: 1,
  },
  SearchContainer: {
    backgroundColor: '#0093C9',
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  SearchInputContainer: {
    backgroundColor: 'rgba(246, 246, 246, 0.2)',
  },
  SearchInput: {
    color: '#FFF',
    fontFamily: 'SourceSansPro_400Regular'
  },
  FilterContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  FilterItems: {
    width: Dimensions.get('window').width / 3,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  FilterItemsDeselected: {
    width: Dimensions.get('window').width / 3,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#878787',
  },
  FilterHeader: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'SourceSansPro_700Bold',
  },
  FilterSubHeader: {
    color: '#fff',
    lineHeight: 14,
    fontFamily: 'SourceSansPro_600SemiBold',
  }
})

export default DrugSearch;
