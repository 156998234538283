import React, { Component } from 'react';
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as RootNavigation from '../scripts/RootNavigation';

class InfoIcon extends Component {
    render() {
        return (
            <View>
                <Icon name="info" color={'#fff'} onPress={() => RootNavigation.navigate('Info')} />
            </View>
        );
    }
  }
export default InfoIcon;