import React, { Component } from 'react';
import { Text, View,  StyleSheet, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { Icon } from 'react-native-elements';


class InfoScreen extends Component {

  render() {    
    return (
      <ScrollView style={styles.MainContainer}>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://drugfreesport.org.za')}>
            <Icon name='external-link-alt' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Visit Website</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
         <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://drugfreesport.org.za/contact-us/')}>
            <Icon name='bullhorn' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Report Doping</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://drugfreesport.org.za/anti-doping-education-workshop-request-form/')}>
            <Icon name='graduation-cap' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Book an Education Session</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://www.facebook.com/drugfreesportZA/')}>
            <Icon name='facebook-square' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Visit Our Facebook Page</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://twitter.com/drugfreesportZA')}>
            <Icon name='twitter-square' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Visit Our Twitter Page</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://www.youtube.com/channel/UCDwKAOvbWcvXp4vbhRgRTOA')}>
            <Icon name='youtube' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Visit Our Youtube Channel</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.ButtonContainer} onPress={()=>Linking.openURL('https://drugfreesport.org.za/')}>
            <Icon name='eye-slash' type="font-awesome-5"  color='#CE0E2D' iconStyle={[styles.headerIconLeft]} />
            <Text style={styles.ButtonText}>Privacy Policy</Text>
            <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight]} />
          </TouchableOpacity>
      </ScrollView>
    );
  }
}


const styles = StyleSheet.create({
  MainContainer: {
    flex: 1,
  },
  ButtonContainer: {
    flexDirection: 'row',
    padding: 15,
    height: 75,
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E9E9E9',
  },
  ButtonText: {
    flex: 1,
    fontSize: 18,
    color: '#333',
    fontFamily: 'SourceSansPro_400Regular'
  },
  headerIconLeft: {
    width: 25,
    height: 20,
    marginRight: 10,
    fontSize: 20,
  },
  headerIconRight: {
    width: 25,
    height: 25,
    marginLeft: 10,
    alignSelf: 'flex-end',
  }
})

export default InfoScreen;
