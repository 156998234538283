import React from 'react';
import { Text, View,  TextInput, StyleSheet, TouchableWithoutFeedback, ActivityIndicator } from 'react-native';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import DrugCard from './drugcard';

// This is the query that Apollo Client will send to the WP site.
const POSTS_SEARCH_QUERY = gql`
query POSTS_SEARCH_QUERY($searchQuery: String!, $limit: Int, $cursor: String!) {
  drugs(where: {search: $searchQuery, orderby: {field: TITLE, order: ASC}}, first: $limit, before: $cursor) {
    edges {
      node {
        id
        title
        content
        modified
        drugCategories {
          edges {
            node {
              name
              slug
            }
          }
        }
      }
    }
    pageInfo {
      total
      hasNextPage
      endCursor
    }
  }
}
`;

const CAT_SEARCH_QUERY = gql`
query POSTS_SEARCH_QUERY($searchQuery: String!, $limit: Int, $cursor: String!, $catType: ID!) {
  drugCategory(idType: DATABASE_ID, id: $catType) {
    drugs(where: {search: $searchQuery, orderby: {field: TITLE, order: ASC}}, first: $limit, before: $cursor) {
      edges {
        node {
          id
          title
          content
          modified
          drugCategories {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      }
      pageInfo {
        total
        hasNextPage
        endCursor
      }
    }
  }
}
`;

    const DrugsList = ({searchQuery, limit, queryMe, cursor, catType}) => (

    <Query query={catType ? CAT_SEARCH_QUERY : POSTS_SEARCH_QUERY} variables={{searchQuery, limit, cursor, catType  }} onCompleted={data => queryMe(catType ?  data.drugCategory.drugs.pageInfo.total : data.drugs.pageInfo.total)}>
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return  (
                <View style={styles.LoadingContainer}>
                  <ActivityIndicator size="large" color="#0093C9" />
                </View>
              );
            }
            if (error) {
              return (
                <View style={styles.LoadingContainer}>
                  <Text>Error</Text>
                </View>
              );
            }
            var drugs = [];
            if (catType) {
              drugs = data.drugCategory.drugs;
            } else {
              drugs = data.drugs;
            }
              if (drugs.edges.length == 0) {
                return (
                  <View  style={styles.LoadingContainer}>
                    <Text style={styles.ResultsText}>No results found. Please adjust search.</Text>
                  </View>
                  )}
              return (
                <DrugCard loading={loading} drugs={drugs} hasMore={drugs.pageInfo.hasNextPage} 
                onLoadMore={() =>
                  fetchMore({
                    variables: {
                      cursor: drugs.pageInfo.endCursor
                    },
                    updateQuery: (prevResult, { fetchMoreResult }) => {
                      const newEdges = catType ? fetchMoreResult.drugCategory.drugs.edges : fetchMoreResult.drugs.edges
                      const pageInfo = catType ? fetchMoreResult.drugCategory.drugs.pageInfo : fetchMoreResult.drugs.pageInfo
                      if (catType) {
                        return newEdges.length
                          ? { 
                              drugCategory: {
                                __typename: prevResult.drugCategory.__typename,
                                drugs: {
                                  __typename: prevResult.drugCategory.drugs.__typename,
                                  edges: [...prevResult.drugCategory.drugs.edges, ...newEdges],
                                  pageInfo
                                  }
                              }
                            }
                          : prevResult
                  } else {
                    return newEdges.length
                          ? {
                              drugs: {
                                __typename: prevResult.drugs.__typename,
                                edges: [...prevResult.drugs.edges, ...newEdges],
                                pageInfo
                              }
                            }
                          : prevResult
                         }
                      }
                      
                  })
                }/>
              )
          }}
        </Query>
)

const styles = StyleSheet.create({
  LoadingContainer: {
    flex: 1,
    paddingTop: 40,
    alignItems: "center",
  },
  ResultsText: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: '#333',
  },
})

export default DrugsList;