import React, { Component } from "react";
import { AppLoading } from "expo";
import { StatusBar } from 'expo-status-bar';
import { View, StyleSheet } from 'react-native';
import { Header, Icon} from 'react-native-elements';
import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationRef } from './scripts/RootNavigation';

import {
  useFonts,
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
  SourceSansPro_700Bold
} from '@expo-google-fonts/source-sans-pro';

import Logo from './assets/icon';
import InfoIcon from './components/InfoIcon';
import InfoScreen from './screens/InfoScreen';
import DrugSearch from './components/drugsearch';

const Stack = createStackNavigator();

const client = new ApolloClient({
  // Change this to the URL of your WordPress site.
  uri: "https://drugfreesport.org.za/graphql/"
});

function HomeScreen() {
  return (
    <ApolloProvider client={client}>
      <DrugSearch  />
    </ApolloProvider>
  );
}


function App() {
  let [fontsLoaded] = useFonts({
    SourceSansPro_400Regular,
    SourceSansPro_600SemiBold,
    SourceSansPro_700Bold
  });
  if (!fontsLoaded) {
    return <AppLoading />
  } else {
  return (
    <View style={styles.MainContainer}>
      <StatusBar style="light" />
      <NavigationContainer ref={navigationRef}>
        <Header
          containerStyle={styles.HeaderContainer}
          placement="left"
          leftComponent={<Logo style={styles.HeaderIcon}/>}
          centerComponent={{ text: 'MEDICATION CHECK', style: { color: '#fff',
          fontSize: 22, fontFamily: "SourceSansPro_600SemiBold"} }}
          rightComponent={<InfoIcon/>}
    />
        <Stack.Navigator>
          <Stack.Screen name="Search" component={HomeScreen}  headerShown={false} 
          options={{
            headerShown: false,
          }}/>
          <Stack.Screen name="Info" component={InfoScreen} options={{
          title: 'Information',
          headerShown: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: '#0093C9',
          },
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontFamily: 'SourceSansPro_600SemiBold',
            textTransform: 'uppercase',
            fontSize: 20,
          },
        }}/>
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}
}

const styles = StyleSheet.create({
  MainContainer: {
    flex: 1,
  },
  HeaderContainer: {
    backgroundColor: '#0093C9',
    borderBottomWidth: 0,
  },
  HeaderIcon: {
    width: 25,
    height: 35,
  }
})

export default App;

