import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Logo(props) {
  return (
    <Svg viewBox="0 0 176.55 276.19" {...props}>
      <Path
        d="M30.57 218C-5.28 218 68 44.48 68 44.48 27.54 123-20.95 276.19 9.63 276.19 61.07 276.19 176.55 0 176.55 0S74.73 218 30.57 218"
        fill="#fff"
      />
      <Path
        d="M50.94 152c-5.69 12.85-6.25 25-1.26 27.25S63.32 172.84 69 160s6.25-25 1.26-27.25-13.64 6.41-19.32 19.25"
        fill="#ce0e2d"
      />
    </Svg>
  )
}

export default Logo