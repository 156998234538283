import React, {Component} from 'react';
import { Text, View, StyleSheet, TouchableWithoutFeedback, Linking, Animated, Image } from 'react-native';
import Moment from 'moment';
import { Icon } from 'react-native-elements';
import Constants from 'expo-constants';
import * as Animatable from 'react-native-animatable';
import Accordion from 'react-native-collapsible/Accordion';
import _, {kebabCase} from 'lodash';
import HTML from "react-native-render-html";

class DrugCard extends Component {

  state = {
    activeSections: [],
    collapsed: true,
    multipleSelect: false,
    scrollY: new Animated.Value(0)
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 20
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
  }
  
  toggleExpanded = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  setSections = sections => {
    this.setState({
      activeSections: sections.includes(undefined) ? [] : sections,
    })
  }

  handleClick = () => {
      this.props.onLoadMore()
  }

  renderHeader(drug, _, isActive) {
    function getIcon (iconName) {
      switch (iconName) {
        case "prohibited-in-competition-only":
          return require('../assets/icons/prohibited-in-competition-only.png');
        case "permitted":
          return require('../assets/icons/permitted.png');
        case "prohibited-in-and-out-of-competition":
          return require('../assets/icons/prohibited-in-and-out-of-competition.png');
        case "prohibited-in-and-out-of-competition-in-males-only":
          return require('../assets/icons/prohibited-in-and-out-of-competition-in-males-only.png');
        case "prohibited-in-competition-only-in-certain-sports":
          return require('../assets/icons/prohibited-in-competition-only-in-certain-sports.png');
        default:
          return require('../assets/icons/prohibited-in-competition-only.png');
      }
    }
    var iconName = kebabCase(drug.node.drugCategories.edges[0].node.slug)
    const icon = getIcon(iconName)
    return (
      <View style={[styles.header, isActive ? styles.active : styles.inactive]} >
        <Image source={icon} style={styles.headerIconLeft} />
        <Text style={styles.headerText}>{drug.node.title}</Text>
        <Icon name='chevron-right'  color='#333' iconStyle={[styles.headerIconRight, isActive ? styles.Open : styles.NotOpen]} />
      </View>
    )
  }

  renderContent(drug, _, isActive) {
    
    return (
      <View style={styles.content}>
        <Animatable.View animation={isActive ? 'fadeIn' : undefined}>
              <Text style={styles.contentText}><Text style={styles.contentTextBold}>Category:</Text> {drug.node.drugCategories.edges[0].node.name}</Text>
              <Text style={styles.contentText}><Text style={styles.contentTextBold}>Last Updated:</Text> {Moment(drug.node.modified).format('ddd, D MMM YYYY')}</Text>
              <Text style={styles.contentText}><Text style={styles.contentTextBold}>Information:</Text> </Text>
              {drug.node.content && 
              <HTML style={styles.contentText} tagsStyles={{p: { fontFamily: 'SourceSansPro_400Regular', color: '#333', fontSize: 15, paddingBottom: 10 }}} html={drug.node.content}/>
              }
              <Text style={styles.contentText}>Find out more and <Text style={styles.Link} onPress={()=> {Linking.openURL('https://en.wikipedia.org/w/index.php?search='+drug.node.title)}}>Search on wikipedia</Text></Text>
        </Animatable.View>
      </View>
    );
  }

  render() {
    const drugs = this.props.drugs || []
    const { multipleSelect, activeSections } = this.state
    return (<Animated.ScrollView
        scrollEventThrottle={16}
        onScroll={ Animated.event([{ 
            nativeEvent: { contentOffset: { y: this.state.scrollY } } }],
            { listener: event => {
                if (this.isCloseToBottom(event.nativeEvent)) {
                  this.props.onLoadMore()
                }
              }
            },
            { useNativeDriver: true }
          )}>
        <Accordion
      activeSections={activeSections}
      sections={drugs.edges}
      touchableComponent={TouchableWithoutFeedback}
      expandMultiple={multipleSelect}
      renderHeader={this.renderHeader}
      renderContent={this.renderContent}
      duration={400}
      onChange={this.setSections}
    /></Animated.ScrollView>
    )
  }

}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    paddingTop: Constants.statusBarHeight,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: '#E9E9E9',
    alignItems: 'center',
  },
  headerIconLeft: {
    width: 25,
    height: 25,
    marginRight: 5,
  },
  headerIconRight: {
    width: 25,
    height: 25,
    marginLeft: 5,
    alignSelf: 'flex-end',
  },
  headerText: {
    flex: 1,
    fontSize: 16,
    fontFamily: 'SourceSansPro_400Regular',
  },
  Open: {
    transform: [{ 
      rotate: '90deg'
    }],
  },
  NotOpen: {
    transform: [{ 
      rotate: '0deg'
    }],
  },
  Link: {
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  content: {
    padding: 15,
    paddingTop: 0,
    backgroundColor: '#fff',
  },
  contentText: {
    color: '#333',
    fontSize: 15,
    fontFamily: 'SourceSansPro_400Regular',
    paddingBottom: 5,
    flex: 1,
  },
  contentTextBold: {
    fontFamily: 'SourceSansPro_600SemiBold',
  },
  active: {
  },
  selectors: {
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  selector: {
    backgroundColor: '#F5FCFF',
    padding: 10,
  },
  activeSelector: {
    fontWeight: 'bold',
  },
  selectTitle: {
    fontSize: 14,
    fontWeight: '500',
    padding: 10,
  },
  multipleToggle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 30,
    alignItems: 'center',
  },
  multipleToggle__title: {
    fontSize: 16,
    marginRight: 8,
  },
});

// {this.props.hasMore && <Button title="Load More" onPress={this.handleClick}></Button>}

export default DrugCard;